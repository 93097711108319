import {useLegacyUser} from '@deezer/react-user';
import {useUserConsent} from '@tempo/cookie';
import {getClientSideUniqId} from '../abTest';
import {CDP} from '@deezer/logcenter';
import {config} from '@modules/config';
import type {TraitEvent} from '@deezer/logcenter/build/types/Sender/CDPSender/types';

const buildDefaultCDP = () => {
	return new CDP(config.get('segmentApiKey'), {
		logger:
			//eslint-disable-next-line no-console
			config.get('environment') === 'deezerdev' ? console.error : undefined,
	});
};

let cdp: CDP;
export const getCDP = (): CDP => {
	if (!cdp) {
		cdp = buildDefaultCDP();
	}
	return cdp;
};

export function useUserIdentifyProfile():
	| (TraitEvent & {lastChanged: number})
	| null {
	const [user] = useLegacyUser();
	const {consentSettings} = useUserConsent();

	if (!user || !consentSettings) return null;
	const profile: TraitEvent & {lastChanged: number} = {
		statistics: !!consentSettings.statistics,
		targetedAds: !!consentSettings.targetedAdvertising,
		userId: user?.USER?.USER_ID?.toString(),
		lastChanged: 0,
	};
	const dzrUniqid = getClientSideUniqId();
	if (dzrUniqid) {
		profile.deezer_unique_id = dzrUniqid;
	}
	if (user?.USER?.EMAIL) {
		profile.email = user?.USER?.EMAIL;
	}
	return profile;
}
