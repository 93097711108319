import {useLegacyUser} from '@deezer/react-user';
import {getClientSideUniqId} from '../abTest';
import {logCenter} from './logCenter';
import {useEffect} from 'react';
import {LogType} from './log/constants';
import type {CDPPageViewId} from './helpers/cdpPageView';
import AdBlockMetric from './log/adBlock';
import {
	AttributionContextLog,
	getAttributionQueryParams,
} from '@deezer/logcenter';

export function useMountEvents(cdpViewId: CDPPageViewId | undefined) {
	const [legacyUser] = useLegacyUser();

	useEffect(() => {
		const dzrUniqId = getClientSideUniqId();

		if (legacyUser && dzrUniqId && cdpViewId) {
			logCenter.log({
				type: LogType.CDP,
				eventName: 'pageView',
				customAttributes: {
					screen_view_name: 'explore',
					screen_view_id_type: 'page',
					screen_view_id: cdpViewId,
				},
			});
		}

		const isDeezerOrEmptyReferrer =
			/deezer(|dev).com/.test(document.referrer) || document.referrer === '';
		const attributionContext = getAttributionQueryParams(
			new URLSearchParams(location.search),
		);
		if (
			legacyUser &&
			/Mozilla/.test(navigator.userAgent) &&
			(Object.keys(attributionContext).length > 0 || !isDeezerOrEmptyReferrer)
		) {
			logCenter.log(
				new AttributionContextLog({
					userID: legacyUser.USER?.USER_ID?.toString() ?? '0',
					marketingParams: attributionContext,
					dzrUniqId,
				}).serialize(),
			);
			setTimeout(() => logCenter.log(new AdBlockMetric().serialize()), 500);
		}
	}, [cdpViewId, legacyUser]);
}
