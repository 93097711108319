export const UNIQ_ID_NAME = 'dzr_uniq_id';

export const generateUniqueId = (): string => {
	const randomBytes = new Uint8Array(19);
	crypto.getRandomValues(randomBytes);
	const hexString = Array.prototype.map
		.call(randomBytes, (x) => ('00' + x.toString(16)).slice(-2))
		.join('');
	return `${UNIQ_ID_NAME}_fr${hexString}`;
};
