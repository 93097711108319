import {GrowthBook} from '@growthbook/growthbook-react';
import {config} from '@modules/config';
import type {
	Attributes,
	Context as GrowthBookContext,
} from '@growthbook/growthbook-react';
import Cookies from 'js-cookie';
import {generateUniqueId, UNIQ_ID_NAME} from './utils';

export const getClientSideUniqId = (): string => {
	const cookie = Cookies.get(UNIQ_ID_NAME);
	if (cookie) return cookie;
	const generated = generateUniqueId();
	Cookies.set(UNIQ_ID_NAME, generated, {expires: 90});
	return generated;
};

const trackingCallback: GrowthBookContext['trackingCallback'] = () => {
	// TODO
};

export type GrowthBookAttributes = Attributes & {
	lang?: string;
	geoip: string;
	dzr_uniq_id: string;
	'page-path': string;
	'page-category': string;
	deezer_user_id?: string;
};
export type StaticABTestAttributes = Pick<
	GrowthBookAttributes,
	'page-path' | 'page-category'
>;

export const buildNewGrowthbookInstance = (): GrowthBook =>
	new GrowthBook({
		trackingCallback,
		enableDevMode: config.get('environment') === 'deezerdev',
	});
