import type {Trackables} from '@app/types/tracking';
import {usePageTracking} from '@app/modules/tracking/use-page-tracking';
import {cdpStrategy, recDirectStrategy, recSender} from './logCenter';
import type {Options} from 'react-tracking';
import {useTracking as defaultUseTracking} from 'react-tracking';
import {useEffect} from 'react';
import {useLegacyUser} from '@deezer/react-user';
import {getCDP, useUserIdentifyProfile} from './cdp';
import {usePrevious} from '@deezer/react-utils';
import {useMountEvents} from './mountEvents';

type Props = {tracking: Partial<Trackables>};
export const TrackingProvider: React.FC<React.PropsWithChildren<Props>> = ({
	children,
	tracking,
}) => {
	const [legacyUser] = useLegacyUser();
	const profile = useUserIdentifyProfile();
	const previousProfile = usePrevious(profile);
	const {Track} = usePageTracking(tracking);

	useEffect(() => {
		if (!profile) return;
		if (!previousProfile) {
			getCDP().init();
		}

		// Not super reliable but reliable enough for our use case and it's a lot cheaper than deep comparison
		if (JSON.stringify(profile) !== JSON.stringify(previousProfile)) {
			cdpStrategy.closeGate();
			getCDP().identify(profile);
			cdpStrategy.openGate();
		}
	}, [profile, previousProfile]);

	useEffect(() => {
		recSender.setAuthToken(legacyUser?.USER_TOKEN ?? '');
		recDirectStrategy.openGate();
	}, [legacyUser]);

	useMountEvents(tracking.cdpViewId);

	return <Track>{children}</Track>;
};

export const useTracking = (
	trackingData?: Partial<Trackables>,
	options?: Partial<Options<Trackables>>,
) => {
	const {trackEvent} = defaultUseTracking(trackingData, options);
	const trackClick =
		(trackingData: Partial<Trackables> = {}) =>
		() => {
			return trackEvent({event: 'click', eventlabel: '', ...trackingData});
		};
	return {trackEvent, trackClick};
};
