import type {RemoteClientV2} from '@deezer/logcenter';
import {RECLog, RECLogContext} from '@deezer/logcenter';

export const AdblockType = 'ads.adblock';
const recLogContext = new RECLogContext({
	name: 'standalone-landing',
	version: '1.0.0',
});

class AdBlockMetric extends RECLog<{adblock: boolean} & RemoteClientV2> {
	constructor() {
		super({
			type: AdblockType,
			version: '1.0.0',
			...recLogContext,
		});
	}

	serialize() {
		return {
			...super.serialize(),
			ts: Math.round(Date.now() / 1000),
			adblock: (window as any)?.analytics?.init === undefined,
		};
	}
}

export default AdBlockMetric;
