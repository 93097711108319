import LogCenter, {
	RECSender,
	BatchStrategy,
	MemoryStorage,
	getBatchConfiguration,
	CDPSender,
	GateStrategy,
} from '@deezer/logcenter';
import {config} from '@modules/config';
import {getCDP} from './cdp';
import {LogType} from './log/constants';

const memoryStorage = new MemoryStorage();

const cdpConfig = {
	handledLogTypes: [LogType.CDP],
};
const recDirectConfig = {
	handledLogTypes: [LogType.ADBLOCK, LogType.ATTRIBUTION_CONTEXT],
};

const batchConfiguration = {
	...getBatchConfiguration({
		maxBatchSize: 10,
		autoSendInterval: 10 * 1000,
	}),
	ignoredLogTypes: [
		...cdpConfig.handledLogTypes,
		...recDirectConfig.handledLogTypes,
	],
};

export const recSender = new RECSender(
	`https://${config.get('recHost')}/1.0/events/`,
	'',
	batchConfiguration,
);

export const cdpStrategy = new GateStrategy(
	new CDPSender(getCDP()),
	memoryStorage,
	cdpConfig,
);
export const recDirectStrategy = new GateStrategy(
	recSender,
	memoryStorage,
	recDirectConfig,
);
export const recBatchStrategy = new BatchStrategy(
	recSender,
	memoryStorage,
	batchConfiguration,
);
export const logCenterInstance = new LogCenter(
	[recBatchStrategy, cdpStrategy, recDirectStrategy],
	memoryStorage,
);

export const logCenter = {
	log: logCenterInstance.log.bind(logCenterInstance),
	flush: recBatchStrategy.flush.bind(recBatchStrategy),
};
