import {getLegacyLocaleFromLocale} from '@deezer/deezer-compatibility';
import {ATTRIBUTION_QUERY_PARAM} from '@deezer/logcenter';
import {config} from '@modules/config';
import nextI18nextConfig from 'next-i18next.config';
import type {ReadonlyURLSearchParams} from 'next/navigation';

export const getCurrentUrl = (router: {
	locale: string | null;
	pathname: string;
}) => {
	let currentUrl = `${config.get('appUrl')}${config.get('basePath')}`;
	if (router.locale && router.locale !== nextI18nextConfig.i18n.defaultLocale) {
		currentUrl += `/${router.locale}`;
	}
	currentUrl += router.pathname.split('?')[0];
	if (!currentUrl.endsWith('/')) {
		currentUrl += '/';
	}
	return currentUrl;
};

export const getAccountUrl = (router: {
	locale: string | null;
	pathname: string;
}) => {
	let accountUrl = `${config.get('hostAccount')}`;
	if (router.locale && router.locale !== nextI18nextConfig.i18n.defaultLocale) {
		accountUrl += `/${router.locale}`;
	}
	accountUrl += router.pathname.split('?')[0];
	if (!accountUrl.endsWith('/')) {
		accountUrl += '/';
	}
	return accountUrl;
};

const filterURLWithQuery = (params: URLSearchParams) => {
	ATTRIBUTION_QUERY_PARAM.forEach((param) => {
		params.delete(param);
	});
	return params;
};

type GetDeezerUrlOptions = {
	/** deezer-seo format locale (will be transformed to legacy "country-like" format).
	 * send undefined if we should not include the locale */
	locale?: string;
	/** pathname to target in www.deezer.com.
	 * If it already contains query they will be included (mandatory for footer links (ex payment gift link)*/
	pathname: string;
	/**
	 * additional query params to add
	 */
	params?: ReadonlyURLSearchParams | URLSearchParams | null;
};
export const getDeezerUrl = ({
	locale,
	pathname,
	params,
}: GetDeezerUrlOptions) => {
	const [path] = pathname.split('?');

	// Include the path to already include potential query string,
	// the path will be overriden anyway afterwards
	const url = new URL(`${config.get('deezerWebsiteUrl')}${pathname}`);
	if (params) {
		Array.from(params.entries()).forEach(([name, value]) => {
			url.searchParams.set(name, value);
		});
	}
	filterURLWithQuery(url.searchParams);
	url.pathname = locale ? `/${getLegacyLocaleFromLocale(locale)}${path}` : path;
	return url.href;
};

/**
 * Method that given a minimal current context can "translate" a
 * url provided by the legacy backend to the current context.
 *
 * It tries to modify locales as little as possible as the backend
 * is supposed to already include them
 */
export const getNormalisedHref = (href: string) => {
	// transform explore urls in relative url: www.deezer.com/explore/fr/lalala -> /fr/lalala
	if (href?.charAt(0) === '/') {
		// /link -> deezer.com/link
		return getDeezerUrl({pathname: href});
	} else if (new URL(href).pathname.startsWith(config.get('basePath'))) {
		let pathElement = href;
		if (href.startsWith('https://')) {
			pathElement = new URL(href).pathname;
		}
		pathElement = pathElement.replace(config.get('basePath'), '');
		return pathElement.startsWith('/') ? pathElement : `/${pathElement}`;
	} else if (href.includes(config.get('serverLegacyApiHost'))) {
		// fr-www.deezer.com -> www.deezer.com
		return href.replace(
			`https://${config.get('serverLegacyApiHost')}`,
			config.get('deezerWebsiteUrl'),
		);
	}
	return href;
};
